import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faLock, faCopy, faCheck, faBookmark, faExpand, faChevronUp, faChevronDown, faUser, faSpinner, faArrowLeft, faInfinity, faTimes, faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTPage.module.scss';

class SBTPage extends Component {
  state = {
    sbtInfo: null,
    userHasSBT: false,
    userIsSbtAdmin: false,
    claimCountdown: 30,
    error: null,
    copiedAddress: null,
    network: this.props.network,
    bookmarked: false,
    showModal: false,
    mintedAddresses: [],
    burnedAddresses: [],
    showStats: true,
    showActions: true,
    showMoreDetails: false,
    intervalId: null,
    loadingMintersBurners: true,
    mintingStatus: 'idle', // 'idle', 'pending', 'success', 'failure'
    burningStatus: 'idle', // 'idle', 'pending', 'success', 'failure'
    mintPassword: '',
    mintStep: 0,
    relevantQuestions: [],
    relevantDocuments: [],
    showPasswordAlert: false,
    mintCountdown: null,
    transactionHash: null,
    burnSearchInput: '', // New state for unified search
    burnSearchResult: null, // New state for search result
    burnSearchType: null, // 'address' or 'tokenId'
  }; 

  componentDidMount() {
    const { SBTAddress } = this.props;
    if (SBTAddress) {
      this.loadSBTInfo();
    }
    this.startMintingEndCountdown();
    this.checkForMintPassword();
    this.fetchRelevantInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.SBTAddress !== prevProps.SBTAddress || this.props.network !== prevProps.network) {
      this.loadSBTInfo();
      this.setState({ network: this.props.network.id });
    }
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  async loadSBTInfo() {
    const SBTAddress = Array.isArray(this.props.SBTAddress) 
      ? this.props.SBTAddress.find(entry => entry.sbtAddress !== undefined)?.sbtAddress 
      : (this.props.SBTAddress.sbtAddress !== undefined ? this.props.SBTAddress.sbtAddress : this.props.SBTAddress);
  
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network.id;
    const cachedSBT = cache[networkID] && cache[networkID].sbtList[SBTAddress];
  
    try {
      let sbtInfo;
      if (cachedSBT && cachedSBT.sbtInfo) {
        sbtInfo = cachedSBT.sbtInfo;
      } else {
        sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, SBTAddress);
      }
  
      const cachedBlockNumber = cachedSBT && cachedSBT.blockNumber ? cachedSBT.blockNumber : 0;
      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
  
      let mintedAddresses = [];
      let burnedAddresses = [];
      if (cachedSBT && cachedSBT.mintedAddresses && cachedSBT.burnedAddresses) {
        mintedAddresses = cachedSBT.mintedAddresses;
        burnedAddresses = cachedSBT.burnedAddresses;
  
        if (cachedSBT.blockNumber < latestBlock) {
          // Fetch new events since cachedBlockNumber
          const newMintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, SBTAddress, cachedBlockNumber + 1, latestBlock);
          const newBurnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, SBTAddress, cachedBlockNumber + 1, latestBlock);
  
          mintedAddresses = [...new Set([...mintedAddresses, ...newMintedAddresses])];
          burnedAddresses = [...new Set([...burnedAddresses, ...newBurnedAddresses])];
        }
      } else {
        // No cached data, fetch all events
        mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, SBTAddress);
        burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, SBTAddress);
      }
      
      this.setState({ sbtInfo, mintedAddresses, burnedAddresses, loadingMintersBurners: false });
  
      if (this.props.account) {
        const userHasSBT = mintedAddresses.includes(this.props.account) && !burnedAddresses.includes(this.props.account);
        const userIsSbtAdmin = sbtInfo.admin === this.props.account;
        this.setState({ userHasSBT, userIsSbtAdmin });
      }
  
      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cache[networkID]?.sbtList,
            [SBTAddress]: {
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock,
            }
          }
        }
      };
      localStorage.setItem('sbtCache', JSON.stringify(newCache));
  
      return sbtInfo;
    } catch (error) {
      console.error("Error in loadSBTInfo:", error);
      this.setState({ error: error.message });
      return null;
    }
  }  

  startMintingEndCountdown() {
    const intervalId = setInterval(() => {
      const { sbtInfo } = this.state;
      if (sbtInfo && sbtInfo.mintingEndTime) {
        const now = new Date().getTime();
        const endTime = sbtInfo.mintingEndTime * 1000;
        const distance = endTime - now;

        if (distance < 0) {
          clearInterval(intervalId);
          this.setState({ mintCountdown: null });
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          this.setState({
            mintCountdown: `${days}d ${hours}h ${minutes}m ${seconds}s`,
          });
        }
      }
    }, 1000);

    this.setState({ intervalId });
  }

  checkForMintPassword = () => {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    if (parts.length >= 4) {
      const mintPassword = parts[3];
      // console.log('Detected mint password:', mintPassword);
      this.setState({ 
        mintPassword,
        mintStep: 0,
        showPasswordAlert: true
      });
    }
  };

  fetchRelevantInfo = () => {
    // Stub function to fetch relevant questions and documents
    this.setState({
      relevantQuestions: ['What is the purpose of this SBT?', 'How can I use this SBT?'],
      relevantDocuments: ['SBT Whitepaper', 'Community Guidelines'],
    });
  };

  handleMint = async () => {
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }
  
    const { SBTAddress } = this.props;
    const { sbtInfo, mintPassword, mintStep } = this.state;
  
    try {
      if (sbtInfo.hasPasswordMint) {
        if (mintStep === 0) {
          this.setState({ mintingStatus: 'pending' });
          const tx = await contractScripts.startClaim('none', SBTAddress);
          console.log('Transaction hash:', tx.hash);
          this.setState({ 
            mintStep: 1, 
            mintingStatus: 'idle',
            transactionHash: tx.hash
          });
          this.startClaimCountdown();
        } else if (mintStep === 2) {
          this.setState({ mintingStatus: 'pending' });
          const tx = await contractScripts.claimWithPassword('none', SBTAddress, mintPassword);
          console.log('Transaction hash:', tx.hash);
          this.setState({ 
            mintStep: 3, 
            mintPassword: '', 
            mintingStatus: 'success',
            transactionHash: tx.hash
          });
          this.loadSBTInfo();
          // Keeping mintingStatus as 'success' so the button stays updated
        }
      } else {
        this.setState({ mintingStatus: 'pending' });
        const tx = await contractScripts.claim('none', SBTAddress);
        console.log('Transaction hash:', tx.hash);
        this.setState({ 
          mintingStatus: 'success',
          transactionHash: tx.hash
        });
        this.loadSBTInfo();
        // Keeping mintingStatus as 'success' for user feedback
      }
    } catch (error) {
      console.error("Minting failed:", error);
      this.setState({ error: error.message, mintingStatus: 'failure' });
      // Keeping mintingStatus as 'failure' for user feedback
    }
  };  

  handleBurnSearchChange = async (event) => {
    const input = event.target.value;
    this.setState({ burnSearchInput: input, burnSearchResult: null, burnSearchType: null });
    
    // Clear if empty
    if (!input) return;
  
    const { SBTAddress } = this.props;
  
    // Check if input is address-like (0x...)
    if (input.startsWith('0x') && input.length === 42) {
      try {
        const tokenId = await contractScripts.getSBTTokenIdByOwner('none', SBTAddress, input);
        if (tokenId) {
          this.setState({
            burnSearchResult: { address: input, tokenId },
            burnSearchType: 'address'
          });
        }
      } catch (error) {
        console.error("Error searching by address:", error);
      }
    } 
    // Check if input is number-like
    else if (/^\d+$/.test(input)) {
      try {
        const address = await contractScripts.getOwnerByTokenId('none', SBTAddress, input);
        if (address) {
          this.setState({
            burnSearchResult: { address, tokenId: input },
            burnSearchType: 'tokenId'
          });
        }
      } catch (error) {
        console.error("Error searching by token ID:", error);
      }
    }
  };  
  
  handleBurn = async () => {
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }
  
    const { SBTAddress } = this.props;
    const { sbtInfo, burnSearchResult } = this.state;
  
    const isAdminBurn = this.state.userIsSbtAdmin && (sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2);
    const isOwnerBurn = this.state.userHasSBT && (sbtInfo.burnAuth === 1 || sbtInfo.burnAuth === 2);
  
    let tokenIdToBurn;
  
    if (isAdminBurn) {
      if (!burnSearchResult?.tokenId) {
        this.setState({ error: "No valid token ID found" });
        return;
      }
      tokenIdToBurn = burnSearchResult.tokenId;
    } else if (isOwnerBurn) {
      tokenIdToBurn = await contractScripts.getSBTTokenIdByOwner('none', SBTAddress, this.props.account);
    } else {
      this.setState({ error: "You are not authorized to burn this SBT." });
      return;
    }
  
    try {
      this.setState({ burningStatus: 'pending' });
      const tx = await contractScripts.burnToken('none', SBTAddress, tokenIdToBurn);
      console.log('Transaction hash:', tx.hash);
      this.loadSBTInfo();
      this.updateCache();
      this.setState({ 
        burningStatus: 'success',
        transactionHash: tx.hash,
        burnSearchInput: '',     // Clear input
        burnSearchResult: null,  // Clear result
        burnSearchType: null     // Clear type
      });
      // Keeping burningStatus as 'success' so the button stays updated
    } catch (error) {
      this.setState({ 
        error: error.message, 
        burningStatus: 'failure',
        burnSearchInput: '',     // Clear input
        burnSearchResult: null,  // Clear result
        burnSearchType: null     // Clear type
      });
      // Keeping burningStatus as 'failure' for user feedback
    }
  };  

  updateCache = async () => {
    const { SBTAddress } = this.props;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network.id;

    const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, SBTAddress);
    const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, SBTAddress);
    const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

    const newCache = {
      ...cache,
      [networkID]: {
        ...cache[networkID],
        lastBlock: latestBlock,
        sbtList: {
          ...cache[networkID]?.sbtList,
          [SBTAddress]: {
            sbtInfo: this.state.sbtInfo,
            mintedAddresses,
            burnedAddresses,
            blockNumber: latestBlock,
          }
        }
      }
    };
    localStorage.setItem('sbtCache', JSON.stringify(newCache));
  };

  startClaimCountdown = () => {
    let countdown = 30;
    const countdownInterval = setInterval(() => {
      countdown--;
      console.log('Countdown:', countdown);
      this.setState({ claimCountdown: countdown });
      if (countdown === 0) {
        clearInterval(countdownInterval);
        this.setState({ mintStep: 2, claimCountdown: 30 });
      }
    }, 1000);
  };

  toggleCreateGroupMode = () => {
    this.setState({ createGroupMode: !this.state.createGroupMode });
  };

  handleTokenIdChange = (event) => {
    this.setState({ tokenIdToBurn: event.target.value });
  };  

  copyToClipboard = (text, addressType) => {
    navigator.clipboard.writeText(text).then(() => {
      this.setState({ copiedAddress: addressType }, () => {
        setTimeout(() => this.setState({ copiedAddress: null }), 2500);
      });
    });
  };

  bookmarkSBT = () => {
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {};
    if (!bookmarks.sbts) bookmarks.sbts = [];
    if (!bookmarks.sbts.includes(this.props.SBTAddress)) {
      bookmarks.sbts.push(this.props.SBTAddress);
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      this.setState({ bookmarked: true });
    }
    this.storeSBTDetails();
  };

  storeSBTDetails = () => {
    const sbtDetails = { ...this.state.sbtInfo, address: this.props.SBTAddress };
    localStorage.setItem('sbtDetails', JSON.stringify(sbtDetails));
  };

  getExplorerUrl = (address) => {
    const network = this.props.chains.find(chain => chain.name === this.props.network.name);
    return network ? `${network.blockExplorers.default.url}/address/${address}` : 'https://sepolia.basescan.org/address/' + address;
  };

  getExplorerLink = (hash) => {
    const network = this.props.network;
    return network ? `${network.blockExplorers.default.url}/tx/${hash}` : 'https://sepolia.basescan.org/tx/' + hash;
  };

  openMintedModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  toggleStats = () => {
    this.setState(prevState => ({ showStats: !prevState.showStats }));
  };

  toggleActions = () => {
    this.setState(prevState => ({ showActions: !prevState.showActions }));
  };

  toggleMoreDetails = () => {
    this.setState(prevState => ({ showMoreDetails: !prevState.showMoreDetails }));
  };

  renderAdminActions = () => {
    const { userIsSbtAdmin, sbtInfo, burnSearchInput, burnSearchResult, burningStatus } = this.state;
    if (!userIsSbtAdmin || !sbtInfo || (sbtInfo.burnAuth !== 0 && sbtInfo.burnAuth !== 2)) return null;
  
    return (
      <div className={styles.adminActions}>
        <h3>Admin Actions</h3>
        <div className={styles.adminBurnSection}>
          <h4>Burn SBT</h4>
          <div className={styles.burnInputGroup}>
            <input
              type="text"
              value={burnSearchInput}
              onChange={this.handleBurnSearchChange}
              placeholder="Enter Address (0x...) or Token ID"
              className={styles.input}
            />
            {burnSearchResult && (
              <div className={styles.burnSearchResult}>
                {burnSearchResult.tokenId && (
                  <p>Token ID: {burnSearchResult.tokenId}</p>
                )}
                {burnSearchResult.address && (
                  <p>Owner: {proposalScripts.getShortenedAddress(burnSearchResult.address, false)}</p>
                )}
              </div>
            )}
            <button 
              onClick={this.handleBurn}
              className={styles.actionButton}
              disabled={(burningStatus !== 'idle' && burningStatus !== 'success') || !burnSearchResult}
            >
              {burningStatus === 'idle' && 'Burn SBT'}
              {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
              {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
              {burningStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
            </button>
          </div>
        </div>
        <button onClick={this.handleMintMoreInvites}>Mint More SBT Invites</button>
      </div>
    );
  };  

  handleMintMoreInvites = () => {
    // Implementation for minting more invites
    console.log("Mint more invites clicked");
  };

  renderMintButton = () => {
    const { sbtInfo, mintStep, claimCountdown, mintingStatus } = this.state;
  
    if (!sbtInfo.hasPasswordMint) {
      return (
        <button 
          onClick={this.handleMint} 
          disabled={mintingStatus !== 'idle' && mintingStatus !== 'success'}
          className={`${styles.actionButton} ${styles.mintButton}`}
        >
          {mintingStatus === 'idle' && 'Mint'}
          {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
          {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
          {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
        </button>
      );
    }
  
    switch (mintStep) {
      case 0:
        return (
          <button 
            onClick={this.handleMint} 
            disabled={mintingStatus !== 'idle'}
            className={`${styles.actionButton} ${styles.mintButton}`}
          >
            {mintingStatus === 'idle' && <>Start Claim <FontAwesomeIcon icon={faLock} /></>}
            {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
            {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
            {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
          </button>
        );
      case 1:
        return (
          <div className={styles.mintProcess}>
            <p className={styles.claimCountdown}>
              Waiting period: {claimCountdown} seconds
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={styles.tooltip}
                id="countdownTooltip"
              />
              <UncontrolledTooltip placement="right" target="countdownTooltip">
                This countdown is a security measure to prevent anyone from stealing your SBT when you expose the password.
              </UncontrolledTooltip>
            </p>
          </div>
        );
      case 2:
        return (
          <button 
            onClick={this.handleMint} 
            disabled={mintingStatus !== 'idle'}
            className={`${styles.actionButton} ${styles.mintButton}`}
          >
            {mintingStatus === 'idle' && <>Finish Claim <FontAwesomeIcon icon={faLock} /></>}
            {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
            {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
            {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
          </button>
        );
      case 3:
        return (
          <div className={styles.mintProcess}>
            <p className={styles.mintSuccess}>SBT successfully minted!</p>
          </div>
        );
      default:
        return null;
    }
  };  

  renderBurnButton = () => {
    const { sbtInfo, userHasSBT, userIsSbtAdmin, burningStatus, burnSearchInput, burnSearchResult } = this.state;
  
    // Check if the user is allowed to burn based on burnAuth
    const canOwnerBurn = userHasSBT && (sbtInfo.burnAuth === 1 || sbtInfo.burnAuth === 2);
    const canAdminBurn = userIsSbtAdmin && (sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2);
  
    if (canOwnerBurn) {
      // Render burn button for the owner
      return (
        <button
          onClick={this.handleBurn}
          disabled={(burningStatus !== 'idle' && burningStatus !== 'success')}
          className={`${styles.actionButton} ${styles.burnButton}`}
        >
          {burningStatus === 'idle' && 'Burn'}
          {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
          {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
          {burningStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
        </button>
      );
    } else if (canAdminBurn) {
      // Render input field and burn button for the admin
      return (
        <div className={styles.burnInputGroup}>
          <input
            type="text"
            value={burnSearchInput}
            onChange={this.handleBurnSearchChange}
            placeholder="Enter Address (0x...) or Token ID"
            className={styles.input}
          />
          {burnSearchResult && (
            <div className={styles.burnSearchResult}>
              {burnSearchResult.tokenId && (
                <p>Token ID: {burnSearchResult.tokenId}</p>
              )}
              {burnSearchResult.address && (
                <p>Owner: {proposalScripts.getShortenedAddress(burnSearchResult.address, false)}</p>
              )}
            </div>
          )}
          <button 
            onClick={this.handleBurn}
            className={styles.actionButton}
            disabled={(burningStatus !== 'idle' && burningStatus !== 'success') || !burnSearchResult}
          >
            {burningStatus === 'idle' && 'Burn SBT'}
            {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
            {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
            {burningStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
          </button>
        </div>
      );
    } else {
      // User cannot burn
      return null;
    }
  };
  

  renderAddressLink = (address, type) => {
    const { copiedAddress } = this.state;
    return (
      <>
        <a href={`/u/${address}`} target="_blank" rel="noopener noreferrer">
          {proposalScripts.getShortenedAddress(address, false)}
        </a>
        <button onClick={() => this.copyToClipboard(address, type)} className={styles.copyButton}>
          <FontAwesomeIcon icon={copiedAddress === type ? faCheck : faCopy} />
        </button>
        <a href={this.getExplorerUrl(address)} target="_blank" rel="noopener noreferrer" className={styles.expandButton}>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      </>
    );
  };

  renderRelevantInfo = () => {
    const { relevantQuestions, relevantDocuments } = this.state;

    return (
      <div className={styles.relevantInfo}>
        <Alert color="info">
          <FontAwesomeIcon icon={faInfoCircle} /> 
          This section shows relevant documents, URLs, and personalities.
        </Alert>
        {/* <h3>Relevant Questions</h3>
        <ul>
          {relevantQuestions.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>
        <h3>Relevant Documents</h3>
        <ul>
          {relevantDocuments.map((document, index) => (
            <li key={index}>{document}</li>
          ))}
        </ul> */}
      </div>
    );
  };

  render() {
    const { SBTAddress, miniaturized } = this.props;
    const { sbtInfo, userHasSBT, userIsSbtAdmin, mintCountdown, error, copiedAddress, bookmarked, showModal, mintedAddresses, burnedAddresses, showStats, showActions, loadingMintersBurners, mintingStatus, burningStatus, showMoreDetails, showPasswordAlert, transactionHash } = this.state;

    if (miniaturized) {
      const SBTAddress = this.props.SBTAddress.sbtAddress !== undefined ? this.props.SBTAddress.sbtAddress : this.props.SBTAddress;
      console.log("miniaturized SBTAddress:", SBTAddress);
    
      if (!this.state.sbtInfo) {
        return <div className={styles.loading}>Loading SBT info...</div>;
      }
    
      const { sbtInfo } = this.state;
      console.log("miniaturized sbtInfo:", sbtInfo);
    
      const imageUrl = sbtInfo.image;
      console.log("imageUrl:", imageUrl);
    
      const sbtName = sbtInfo.name;
      console.log("sbtName:", sbtName);
    
      return (
        <div className={styles.sbtItem}>
          <img
            src={imageUrl}
            alt={sbtName}
            onClick={() => window.open(`${window.location.origin}/sbt/${SBTAddress}`, '_blank')}
            className={styles.sbtImage}
          />
          <p id={styles.miniSbtName}> {sbtName} </p>
          <p id={styles.miniSbtAddress}>{proposalScripts.getShortenedAddress(SBTAddress, false)}</p>
        </div>
      );
    }

    const burnAuthLabels = ["Issuer Only", "Owner Only", "Both", "Neither"];
    const addressDisplay = proposalScripts.getShortenedAddress(SBTAddress, false);
    const renderedAdminActions = this.renderAdminActions();

    if (!SBTAddress) {
      return null;
    }

    if (error) {
      return <div className={styles.error}>Error: {error}</div>;
    }

    return (
      <div className={styles.sbtPage}>
        <button onClick={() => window.location.href = "/sbts"} className={styles.backButton}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to SBTs list
        </button>
        {showPasswordAlert && (
          <Alert color="info" className={styles.passwordAlert}>
            Password detected – click "start claim" to get your SBT
          </Alert>
        )}
        {sbtInfo ? (
          <>
            <div className={styles.sbtInfo}>
              <div className={styles.leftColumn}>
                <div className={styles.bookmarkIcon}>
                  <button onClick={this.bookmarkSBT} className={styles.bookmarkButton} style={{ color: bookmarked ? '#00adb5' : undefined }}>
                    <FontAwesomeIcon icon={faBookmark} />
                  </button>
                  <a href={this.getExplorerUrl(SBTAddress)} target="_blank" rel="noopener noreferrer" className={styles.contractLink}>
                    {addressDisplay}
                  </a>
                  <button onClick={() => this.copyToClipboard(SBTAddress, 'contract')} className={styles.copyButton}>
                    <FontAwesomeIcon icon={copiedAddress === 'contract' ? faCheck : faCopy} />
                  </button>
                </div>
                <div className={styles.image}>
                  <img src={sbtInfo.image} alt="SBT" />
                </div>
                <div className={styles.description}>
                  <h1>{sbtInfo.name}</h1>
                  <p>{sbtInfo.description}</p>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.statsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleStats}>
                    STATS <FontAwesomeIcon icon={showStats ? faChevronUp : faChevronDown} />
                  </h2>
                  {showStats && (
                    <div className={styles.stats}>
                      <p>
                        <span className={styles.label}>Minted:</span> {mintedAddresses.length} / {sbtInfo.maxTokens === 0 ? <FontAwesomeIcon icon={faInfinity} /> : sbtInfo.maxTokens}
                        <button onClick={this.openMintedModal} className={styles.expandButton}>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                      </p>
                      {(sbtInfo.mintingEndTime != 0) && (
                        <p><span className={styles.label}>Minting ends:</span>
                          {sbtInfo.mintingEndTime * 1000 > Date.now() ? (
                            <span>{mintCountdown}</span>
                          ) : (
                            new Date(sbtInfo.mintingEndTime * 1000).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' })
                          )}
                        </p>
                      )}
                      <p id="burnAuthTooltip"><span className={styles.label}>Burnable by:</span> {burnAuthLabels[sbtInfo.burnAuth]}</p>
                      <UncontrolledTooltip placement="right" target="burnAuthTooltip">
                        Specify who can burn the token: Admin Only, Owner Only, Both, or Neither.
                      </UncontrolledTooltip>
                      <p><span className={styles.label}>Deployer:</span> {this.renderAddressLink(sbtInfo.admin, 'deployer')}</p>
                      <p><span className={styles.label}>Admin:</span> {sbtInfo.admin ? this.renderAddressLink(sbtInfo.admin, 'admin') : "None"}</p>
                    </div>
                  )}
                </div>
                <div className={styles.actionsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleActions}>
                    ACTIONS <FontAwesomeIcon icon={showActions ? faChevronUp : faChevronDown} />
                  </h2>
                  {showActions && (
                    <div className={styles.actions}>
                      {this.renderMintButton()}
                      {this.renderBurnButton()}
                      {renderedAdminActions}
                    </div>
                  )}
                </div>
                <div className={styles.moreDetailsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleMoreDetails}>
                    MORE <FontAwesomeIcon icon={showMoreDetails ? faChevronUp : faChevronDown} />
                  </h2>
                  {showMoreDetails && this.renderRelevantInfo()}
                </div>
                {transactionHash && (
                  <div className={styles.transactionInfo}>
                    <p>Transaction Hash: <a href={this.getExplorerLink(transactionHash)} target="_blank" rel="noopener noreferrer">{transactionHash}</a></p>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        )}
    
        <Modal isOpen={showModal} toggle={this.closeModal} id={styles.mintingAddressModal}>
          <ModalHeader toggle={this.closeModal}>Minted Addresses</ModalHeader>
          <ModalBody>
            {loadingMintersBurners ? (
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            ) : (
              <ul className={styles.mintingAddressList}>
                {mintedAddresses.map((address, index) => (
                  <li key={index} className={styles.mintingAddressItem}>
                    <a href={`/u/${address}`} target="_blank" rel="noopener noreferrer">
                      {address}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SBTPage;