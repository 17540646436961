import React from 'react';
import { Button, FormGroup, Label, CustomInput } from 'reactstrap';
import { ethers } from 'ethers';
import styles from './SBTSelector.module.scss';
import contractScripts from '../Buttons/contractScripts.js';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';
import Select from 'react-select';

class SBTSelector extends React.Component {
  state = {
    selectedSBTs: [],
    customSBTAddress: '',
    sbtOptions: [],
    showManualInput: false,
    selectedOption: null,
  };

  componentDidMount() {
    this.loadSBTOptions();
  }

  loadSBTOptions = async () => {
    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network;
    let sbtList = sbtCache[networkID] ? sbtCache[networkID].sbtList : {};

    // Include featured SBTs
    for (const sbtAddress of featured_SBTs_LIST) {
      if (!sbtList[sbtAddress]) {
        // Fetch metadata
        try {
          const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
          sbtList[sbtAddress] = {
            sbtAddress,
            sbtInfo,
          };
          // Update cache
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          sbtCache[networkID].sbtList[sbtAddress] = sbtList[sbtAddress];
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT metadata:', error);
          // Handle error appropriately
        }
      }
    }

    // Build sbtOptions
    const sbtOptions = Object.values(sbtList).map(sbt => {
      const sbtInfo = sbt.sbtInfo || sbt.metadata;
      return {
        address: sbt.sbtAddress || sbt.address,
        name: sbtInfo && sbtInfo.name ? sbtInfo.name : 'Unnamed SBT',
        image: sbtInfo && sbtInfo.image ? sbtInfo.image : null,
      };
    });

    this.setState({ sbtOptions });
  };

  handleSBTSelection = async (selectedOption) => {
    if (!selectedOption) return;
    const selectedAddress = selectedOption.value;
    if (selectedAddress && ethers.utils.isAddress(selectedAddress)) {
      const alreadySelected = this.state.selectedSBTs.find(sbt => sbt.address === selectedAddress);
      if (!alreadySelected) {
        let selectedSBT = this.state.sbtOptions.find(sbt => sbt.address === selectedAddress);
        if (!selectedSBT) {
          // SBT not in options, need to fetch metadata
          try {
            const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, selectedAddress);
            selectedSBT = { address: selectedAddress, name: sbtInfo.name || selectedAddress, image: sbtInfo.image || null };
          } catch (error) {
            console.error('Error fetching SBT metadata:', error);
            selectedSBT = { address: selectedAddress, name: 'Unnamed SBT', image: null };
          }
        }
        this.setState(prevState => ({
          selectedSBTs: [...prevState.selectedSBTs, selectedSBT],
          selectedOption: null, // Reset selector
        }), () => {
          this.props.onAddSBT(selectedAddress);
        });
      }
    }
  };

  handleCustomSBTAddressInput = (e) => {
    this.setState({ customSBTAddress: e.target.value });
  };

  handleAddCustomSBT = async () => {
    const { customSBTAddress } = this.state;
    if (customSBTAddress && ethers.utils.isAddress(customSBTAddress)) {
      const alreadySelected = this.state.selectedSBTs.find(sbt => sbt.address === customSBTAddress);
      if (!alreadySelected) {
        // Fetch metadata
        let sbtName = customSBTAddress;
        let sbtImage = null;
        try {
          const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, customSBTAddress);
          sbtName = sbtInfo.name || customSBTAddress;
          sbtImage = sbtInfo.image || null;
          // Update cache
          const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
          const networkID = this.props.network;
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          sbtCache[networkID].sbtList[customSBTAddress] = {
            sbtAddress: customSBTAddress,
            sbtInfo,
          };
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT metadata:', error);
        }
        const customSBT = { address: customSBTAddress, name: sbtName, image: sbtImage };
        this.setState(prevState => ({
          selectedSBTs: [...prevState.selectedSBTs, customSBT],
          customSBTAddress: '',
        }), () => {
          this.props.onAddSBT(customSBTAddress);
        });
      }
    }
  };

  toggleManualInput = () => {
    this.setState(prevState => ({ showManualInput: !prevState.showManualInput }));
  };

  removeSBT = (address) => {
    this.setState(prevState => ({
      selectedSBTs: prevState.selectedSBTs.filter(sbt => sbt.address !== address),
    }), () => {
      this.props.onRemoveSBT(address);
    });
  };

  formatOptionLabel = ({ label, image, value }) => (
    <div className={styles.optionLabel}>
      {image && <img src={image} alt="" className={styles.optionImage} />}
      <span>{label}</span>
    </div>
  );

  render() {
    const { customSBTAddress, sbtOptions, showManualInput, selectedSBTs, selectedOption } = this.state;
    const options = sbtOptions.map(sbt => ({
      value: sbt.address,
      label: sbt.name,
      image: sbt.image,
    }));
  
    return (
      <div className={styles.sbtSelector}>
        <FormGroup>
          <Label className={styles.sbtLabel}>{this.props.label || 'Select SBTs:'}</Label>
          <Select
            id={`sbtDropdown-${this.props.id}`}
            options={options}
            onChange={this.handleSBTSelection}
            className={styles.sbtDropdown}
            formatOptionLabel={this.formatOptionLabel}
            styles={{
              option: (provided) => ({ ...provided, color: 'black' }),
              singleValue: (provided) => ({ ...provided, color: 'black' }),
              input: (provided) => ({ ...provided, color: 'black' }),
              control: (provided) => ({ ...provided, color: 'black' }),
              menu: (provided) => ({ ...provided, color: 'black' }),
            }}
            value={selectedOption}
            placeholder="Select SBT..."
          />
        </FormGroup>
        <FormGroup>
          <CustomInput
            type="switch"
            id={`manualInputToggle-${this.props.id}`}
            name={`manualInputToggle-${this.props.id}`}
            label="Manual SBT Address Input"
            checked={showManualInput}
            onChange={this.toggleManualInput}
          />
        </FormGroup>
        {showManualInput && (
          <div>
            <input
              id={`customSbtAddressInput-${this.props.id}`}
              type="text"
              placeholder="Enter SBT Ethereum address"
              value={customSBTAddress}
              onChange={this.handleCustomSBTAddressInput}
              className={styles.sbtAddressInput}
              style={{ color: 'black' }}
            />
            <Button
              id={`customSbtAddButton-${this.props.id}`}
              onClick={this.handleAddCustomSBT}
              className={styles.sbtAddButton}
              disabled={!ethers.utils.isAddress(customSBTAddress)}
            >
              Add Address
            </Button>
          </div>
        )}
        <div className={styles.selectedSBTs}>
          {selectedSBTs.map(sbt => (
            <div key={sbt.address} className={styles.sbtItem}>
              {sbt.image && (
                <img src={sbt.image} alt={sbt.name} className={styles.sbtThumbnail} />
              )}
              <div className={styles.sbtDetails}>
                <span className={styles.sbtName}>{sbt.name}</span>
                <span className={styles.sbtAddress}>{sbt.address}</span>
              </div>
              <Button close onClick={() => this.removeSBT(sbt.address)} />
            </div>
          ))}
        </div>
      </div>
    );
  }  
}

export default SBTSelector;
