import React from 'react';
import { FormGroup, Label, Input, Button, Collapse } from 'reactstrap';
import { ethers } from 'ethers';
import styles from './SBTSelector.module.scss';
import SBTSelector from './SBTSelector.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSpinner } from '@fortawesome/free-solid-svg-icons';

class SBTFilter extends React.Component {
  state = {
    selectedSBTGroups: [], // Array of addresses to include
    excludedSBTGroups: [], // Array of addresses to exclude
    onlyVerifiedHumans: false,
    showFilterOptions: false,
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.responses !== this.props.responses ||
      prevProps.mode !== this.props.mode ||
      prevState.selectedSBTGroups !== this.state.selectedSBTGroups ||
      prevState.excludedSBTGroups !== this.state.excludedSBTGroups ||
      prevState.onlyVerifiedHumans !== this.state.onlyVerifiedHumans
    ) {
      this.applyFilter();
    }
  }

  applyFilter = async () => {
    const { responses, mode, provider, network } = this.props;
    const { selectedSBTGroups, excludedSBTGroups, onlyVerifiedHumans } = this.state;
  
    this.setState({ loading: true });
  
    // If no filters are applied, return original responses
    if (selectedSBTGroups.length === 0 && excludedSBTGroups.length === 0 && !onlyVerifiedHumans) {
      this.props.onFilter(responses);
      this.setState({ loading: false });
      return;
    }
  
    // Create a mapping of SBT address to set of holders
    const sbtHoldersMap = {};
  
    for (const sbtAddress of [...selectedSBTGroups, ...excludedSBTGroups]) {
      if (!sbtHoldersMap[sbtAddress]) {
        let mintedAddresses = [];
        let burnedAddresses = [];
  
        // Fetch data from blockchain
        try {
          mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
          burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
  
          // Update cache
          const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
          const networkID = network;
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          if (!sbtCache[networkID].sbtList[sbtAddress]) {
            sbtCache[networkID].sbtList[sbtAddress] = {};
          }
          sbtCache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
          sbtCache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT holders:', error);
          // Handle error appropriately
        }
  
        const netMintedAddresses = mintedAddresses.filter(addr => !burnedAddresses.includes(addr));
        sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
      }
    }
  
    const filterAddress = (address) => {
      // Check if address should be excluded
      for (const sbtAddress of excludedSBTGroups) {
        const holdersSet = sbtHoldersMap[sbtAddress];
        if (holdersSet && holdersSet.has(address)) {
          return false; // Exclude if holds any excluded SBT
        }
      }
      // If we have selected SBTs, check if address holds any
      if (selectedSBTGroups.length > 0) {
        let holdsIncludedSBT = false;
        for (const sbtAddress of selectedSBTGroups) {
          const holdersSet = sbtHoldersMap[sbtAddress];
          if (holdersSet && holdersSet.has(address)) {
            holdsIncludedSBT = true;
            break;
          }
        }
        if (!holdsIncludedSBT) {
          return false; // Exclude if doesn't hold any included SBT
        }
      }
      // Check for verified humans
      if (onlyVerifiedHumans) {
        const humanSBTAddress = '...'; // Replace with the address of the verified human SBT
        const humanHoldersSet = sbtHoldersMap[humanSBTAddress];
        if (humanHoldersSet && !humanHoldersSet.has(address)) {
          return false; // Exclude if not a verified human
        }
      }
      return true;
    };
  
    let filteredResponses = [];
  
    if (mode === 'survey') {
      filteredResponses = responses.filter(response => filterAddress(response.responder));
    } else if (mode === 'questions') {
      filteredResponses = {};
      Object.entries(responses).forEach(([questionId, responseArray]) => {
        filteredResponses[questionId] = responseArray.filter(r => filterAddress(r.responder));
      });
    }
  
    this.setState({ loading: false }, () => {
      this.props.onFilter(filteredResponses);
    });
  };
  

  handleAddSBTInclude = (address) => {
    if (!this.state.selectedSBTGroups.includes(address)) {
      this.setState(prevState => ({
        selectedSBTGroups: [...prevState.selectedSBTGroups, address],
      }));
    }
  };

  handleRemoveSBTInclude = (address) => {
    this.setState(prevState => ({
      selectedSBTGroups: prevState.selectedSBTGroups.filter(addr => addr !== address),
    }));
  };

  handleAddSBTExclude = (address) => {
    if (!this.state.excludedSBTGroups.includes(address)) {
      this.setState(prevState => ({
        excludedSBTGroups: [...prevState.excludedSBTGroups, address],
      }));
    }
  };

  handleRemoveSBTExclude = (address) => {
    this.setState(prevState => ({
      excludedSBTGroups: prevState.excludedSBTGroups.filter(addr => addr !== address),
    }));
  };

  toggleVerifiedHumans = () => {
    this.setState(prevState => ({
      onlyVerifiedHumans: !prevState.onlyVerifiedHumans,
    }));
  };

  toggleFilterOptions = () => {
    this.setState(prevState => ({
      showFilterOptions: !prevState.showFilterOptions,
    }));
  };

  render() {
    const { showFilterOptions, selectedSBTGroups, excludedSBTGroups, onlyVerifiedHumans, loading } = this.state;
  
    return (
      <div className={styles.sbtFilter}>
        <Button
          onClick={this.toggleFilterOptions}
          className={styles.filterButton}
        >
          <FontAwesomeIcon icon={faCog} /> Filter
        </Button>
  
        <Collapse isOpen={showFilterOptions}>
          <div className={styles.filterOptions}>
            <SBTSelector
              id="include"
              network={this.props.network}
              provider={this.props.provider}
              onAddSBT={this.handleAddSBTInclude}
              onRemoveSBT={this.handleRemoveSBTInclude}
              label={"Include Responses from SBT Holders"}
            />
  
            <div className={styles.selectedAddresses}>
              {selectedSBTGroups.map(address => (
                <div key={address} className={styles.addressTag}>
                  {/* Optional rendering of selected addresses */}
                </div>
              ))}
            </div>
  
            <SBTSelector
              id="exclude"
              network={this.props.network}
              provider={this.props.provider}
              onAddSBT={this.handleAddSBTExclude}
              onRemoveSBT={this.handleRemoveSBTExclude}
              label={"Exclude Responses from SBT Holders"}
            />
  
            <div className={styles.selectedAddresses}>
              {excludedSBTGroups.map(address => (
                <div key={address} className={styles.addressTag}>
                  {/* Optional rendering of selected addresses */}
                </div>
              ))}
            </div>
  
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={onlyVerifiedHumans}
                  onChange={this.toggleVerifiedHumans}
                />
                Only see verified human responses
              </Label>
            </FormGroup>
          </div>
        </Collapse>
  
        {loading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Filtering responses...</p>
          </div>
        )}
      </div>
    );
  }  
}

export default SBTFilter;
