import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faVoteYea, faSpinner, faScroll, faUsersCog, faBuilding, faArrowRight, faArrowLeft, faChevronDown, faChevronUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from './CommunityTab.module.scss';
import historicalFigures from '../../variables/historical_figure_users.json';
import logoRxC from "../../assets/img/rxcLogo.png";
import logoPolis from "../../assets/img/polisLogo.png";
import { Modal, ModalHeader, ModalBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import contractScripts from '../Buttons/contractScripts.js';
import SBTsList from '../SBTs/SBTsList';

class CommunityTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySection: 'groups',
      showModal: false,
      modalContent: { title: '', content: '' },
      showMoreLeaderboard: false,
      hideSimulatedUsers: false,
      hideHumanUsers: false, 
      sbtsCreatedCount: 0,
      loadingSbtsCreated: true,
      uniqueUsers: [],
      surveysCreatedCount: 0,
      surveyResponsesCount: 0,
      uniqueQuestionsCount: 0,
      loadingSurveyData: true,
      surveysList: [],
      questionsList: [],
    };
  
    // Base stats array - counts will be updated dynamically
    this.stats = [
      { icon: faUsers, count: 0, label: 'Users' },
      { icon: faVoteYea, count: 0, label: 'Questions' }, // Changed label to 'Questions'
      { icon: faScroll, count: 0, label: 'Surveys Created' },
      { icon: faUsersCog, count: 0, label: 'SBT Groups' },
    ];
  
    this.groups = [
      {
        name: 'RadicalxChange',
        logo: logoRxC,
        description: 'RadicalxChange is a global movement dedicated to reimagining the building blocks of democracy and markets in order to uphold fairness, plurality, and meaningful participation in a rapidly changing world.'
      },
      {
        name: 'Pol.is',
        logo: logoPolis,
        description: 'Pol.is is a pioneering software platform that facilitates large-scale conversations and helps identify areas of consensus and disagreement on complicated issues.'
      },
    ];
  }
    

  async componentDidMount() {
    await this.fetchSbtsCreatedCount();
    await this.fetchSurveyDataFromCache();
  }
  

  componentDidUpdate(prevProps) {
    // Refetch if network or provider changes
    if (prevProps.network?.id !== this.props.network?.id || prevProps.provider !== this.props.provider) {
      this.fetchSbtsCreatedCount();
    }
  }

  async fetchSbtsCreatedCount() {
    const { provider, network } = this.props;
    this.setState({ loadingSbtsCreated: true });
    
    try {
      const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
      if (cache[network?.id] && cache[network?.id].sbtList) {
        const sbtsCreated = Object.keys(cache[network.id].sbtList).length;
        this.setState({
          sbtsCreatedCount: sbtsCreated,
          loadingSbtsCreated: false
        });
        this.stats[3].count = sbtsCreated;
        return;
      }
  
      const count = await contractScripts.countSBTCreated(provider);
      this.setState({
        sbtsCreatedCount: count,
        loadingSbtsCreated: false
      });
      this.stats[3].count = count;
    } catch (error) {
      console.error('Error fetching SBT Groups count:', error);
      this.setState({
        sbtsCreatedCount: 0,
        loadingSbtsCreated: false
      });
      this.stats[3].count = 0;
    }
  }

  async fetchSurveyDataFromCache() {
    const surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
      surveyIDs: [],
      questionIDs: [],
      questionResponses: {},
      arweaveContent: {}
    };
  
    // Get surveys created count
    const surveysCreatedCount = surveyCache.surveyIDs.length;
  
    // Get unique questions count
    const uniqueQuestionsCount = surveyCache.questionIDs.length;
  
    // Get survey responses count and unique users
    const uniqueUsersSet = new Set();
    let surveyResponsesCount = 0;
  
    for (const questionId in surveyCache.questionResponses) {
      const responses = surveyCache.questionResponses[questionId];
      surveyResponsesCount += responses.length;
      for (const response of responses) {
        uniqueUsersSet.add(response.responder);
      }
    }
  
    // From sbtCache, collect unique users
    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network?.id;
    if (networkID && sbtCache[networkID]) {
      const sbtList = sbtCache[networkID].sbtList || {};
      for (const sbtAddress in sbtList) {
        const mintedAddresses = sbtList[sbtAddress].mintedAddresses || [];
        for (const address of mintedAddresses) {
          uniqueUsersSet.add(address);
        }
      }
    }
  
    // Get surveys list
    const surveysList = surveyCache.surveyIDs.map(surveyId => {
      const surveyContent = surveyCache.arweaveContent[surveyId] || {};
      // Calculate number of responses for this survey
      let numResponses = 0;
      const questionIDs = surveyContent.questionIDs || [];
      const questionResponses = surveyCache.questionResponses || {};
      const responderSet = new Set();
      questionIDs.forEach(qid => {
        const responses = questionResponses[qid] || [];
        responses.forEach(r => {
          responderSet.add(r.responder);
        });
      });
      numResponses = responderSet.size;
  
      return {
        id: surveyId,
        title: surveyContent.title || 'Untitled Survey',
        responsesCount: numResponses
      };
    });
  
    // Update stats
    this.stats[0].count = uniqueUsersSet.size; // Users
    this.stats[1].count = uniqueQuestionsCount; // Questions
    this.stats[2].count = surveysCreatedCount; // Surveys Created
  
    // Update state
    this.setState({
      uniqueUsers: Array.from(uniqueUsersSet),
      surveysCreatedCount,
      surveyResponsesCount,
      uniqueQuestionsCount,
      surveysList,
      loadingSurveyData: false,
    });
  }

  toggleDisplaySection = () => {
    this.setState(prevState => ({
      displaySection: prevState.displaySection === 'groups' ? 'individuals' : 'groups'
    }));
  }

  handleUserClick = (user) => {
    if (user.username.startsWith('0x')) {
      window.open(`/u/${user.username}`, '_blank');
    } else {
      window.open(`/su/${user.username}`, '_blank');
    }
  }

  handleStatClick = (stat) => {
    const { provider, network, account, loginComplete } = this.props;
    
    if (stat.label === 'SBT Groups') {
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: (
            <SBTsList
              provider={provider}
              network={network}
              account={account}
              loginComplete={loginComplete}
              miniaturized={true}
            />
          )
        },
        showModal: true
      });
    } else if (stat.label === 'Users') {
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: (
            <div className={styles.userList}>
              {this.state.uniqueUsers.map((address, index) => (
                <div key={index} className={styles.userItem}>
                  <a href={`/u/${address}`} target="_blank" rel="noopener noreferrer">
                    {address}
                  </a>
                </div>
              ))}
            </div>
          )
        },
        showModal: true
      });
    } else if (stat.label === 'Surveys Created') {
        this.setState({
          modalContent: {
            title: `${stat.label} Details`,
            content: (
              <div className={styles.surveyList}>
                {this.state.surveysList.map((survey, index) => (
                  <div key={index} className={styles.surveyItem}>
                    <a href={`/survey/${survey.id}`} target="_blank" rel="noopener noreferrer" className={styles.surveyLink}>
                      {survey.title}
                    </a>
                    <span className={styles.responsesCount} onClick={() => window.open(`/results/${survey.id}`, '_blank')}>
                      ({survey.responsesCount})
                    </span>
                  </div>
                ))}
              </div>
            )
          },
          showModal: true
        });
       } else {
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: `Detailed information about ${stat.label.toLowerCase()} will be displayed here.`
        },
        showModal: true
      });
    }
  }
  

  getUserInfo(address) {
    // Placeholder function to get user info; replace with actual implementation
    return {
      name: address, // For now, just use the address
      username: address,
      avatar: `https://robohash.org/${address}`,
      // Karma: 0, // Commented out as per instructions
    };
  }
  

  handleGroupClick = (group) => {
    this.setState({
      modalContent: {
        title: group.name,
        content: (
          <div>
            <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
            <p>{group.description}</p>
          </div>
        )
      },
      showModal: true
    });
  }

  renderLeaderboard() {
    const { uniqueUsers, hideSimulatedUsers, hideHumanUsers, showMoreLeaderboard } = this.state;
  
    let realUsers = uniqueUsers.map(address => this.getUserInfo(address));
    let simulatedUsers = historicalFigures;
  
    let leaderboardData = [];
  
    if (hideSimulatedUsers && hideHumanUsers) {
      leaderboardData = [];
    } else if (hideSimulatedUsers) {
      leaderboardData = realUsers;
    } else if (hideHumanUsers) {
      leaderboardData = simulatedUsers;
    } else {
      leaderboardData = [...simulatedUsers, ...realUsers];
    }
  
    // Remove duplicates
    const uniqueLeaderboardData = Array.from(new Set(leaderboardData.map(user => user.username)))
      .map(username => {
        return leaderboardData.find(user => user.username === username)
      });
  
    // Sort alphabetically by name
    uniqueLeaderboardData.sort((a, b) => a.name.localeCompare(b.name));
  
    const topThree = uniqueLeaderboardData.slice(0, 4);
    const remainingEntries = uniqueLeaderboardData.slice(4);
  
    if (uniqueLeaderboardData.length === 0) {
      return <div className={styles.noUsers}>None yet!</div>;
    }
  
    return (
      <>
        {topThree.map((user, index) => (
          <div key={index} className={styles.leaderboardItem} onClick={() => this.handleUserClick(user)}>
            <img src={user.avatar} alt={user.name} className={styles.avatar} />
            <span className={styles.name}>
              {user.name}
              {!user.username.startsWith('0x') && (
                <>
                  <span className={styles.simBadge} id={`sim-tooltip-${index}`}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                  <UncontrolledTooltip placement="right" target={`sim-tooltip-${index}`}>
                    This is a simulated user.
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        ))}
        <Collapse isOpen={showMoreLeaderboard}>
          {remainingEntries.map((user, index) => (
            <div key={index + 4} className={styles.leaderboardItem} onClick={() => this.handleUserClick(user)}>
              <img src={user.avatar} alt={user.name} className={styles.avatar} />
              <span className={styles.name}>
                {user.name}
                {!user.username.startsWith('0x') && (
                  <>
                    <span className={styles.simBadge} id={`sim-tooltip-${index + 4}`}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    <UncontrolledTooltip placement="right" target={`sim-tooltip-${index + 4}`}>
                      This is a simulated user.
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
          ))}
        </Collapse>
        {uniqueLeaderboardData.length > 4 && (
          <button 
            onClick={() => this.setState(prevState => ({ showMoreLeaderboard: !prevState.showMoreLeaderboard }))} 
            className={styles.showMoreButton}
          >
            {showMoreLeaderboard ? (
              <>
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </>
            ) : (
              <>
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </button>
        )}
      </>
    );
  }
  

  render() {
    const { displaySection, showModal, modalContent, hideSimulatedUsers, hideHumanUsers, loadingSbtsCreated, sbtsCreatedCount } = this.state;
  
    // Update the SBT Groups count in stats array
    this.stats[3].count = sbtsCreatedCount;
  
    return (
      <div className={styles.communityTab}>
        <div className={styles.leaderboardSection}>
          <h2 className={styles.sectionTitle}>Leaderboard</h2>
          <div className={styles.leaderboardHeader}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideSimulatedUsers}
                onChange={() => this.setState(prevState => ({ hideSimulatedUsers: !prevState.hideSimulatedUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Simulated Users
            </label>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideHumanUsers}
                onChange={() => this.setState(prevState => ({ hideHumanUsers: !prevState.hideHumanUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Human Users
            </label>
          </div>
          <div className={styles.content}>
            <div className={styles.leaderboard}>
              <div className={styles.leaderboardHeader}>
                <span>Name</span>
                <span>Karma</span>
              </div>
              {this.renderLeaderboard()}
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.statsSection}>
            <h2 className={styles.sectionTitle}>Statistics</h2>
            <div className={styles.statsGrid}>
              {this.stats.map((stat, index) => (
                <div key={index} className={styles.statItem} onClick={() => this.handleStatClick(stat)}>
                  <FontAwesomeIcon icon={stat.icon} size="2x" className={styles.statIcon} />
                  <span className={styles.statCount}>
                    {stat.label === 'SBT Groups' && loadingSbtsCreated ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      stat.count
                    )}
                  </span>
                  <span className={styles.statLabel}>{stat.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.recognitionSection}>
            <h2 className={styles.sectionTitle}>Recognition</h2>
            <div className={styles.content}>
              {displaySection === 'groups' ? (
                <>
                  <div className={styles.groupsGrid}>
                    {this.groups.map((group, index) => (
                      <div key={index} className={styles.groupItem} onClick={() => this.handleGroupClick(group)}>
                        <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
                        <span>{group.name}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.noIndividuals}>
                    No recognized individuals yet.
                  </div>
                  <button onClick={this.toggleDisplaySection} className={styles.switchButton}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Groups
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <Modal isOpen={showModal} toggle={() => this.setState({ showModal: false })} className={styles.modal}>
        <ModalHeader toggle={() => this.setState({ showModal: false })} className={styles.modalHeader}>
          {modalContent.title}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {modalContent.content}
        </ModalBody>
      </Modal>
    </div>
    );
  }
}

export default CommunityTab;