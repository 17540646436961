import React, { Component } from "react";
import classnames from "classnames";

// CSS and Icons
import styles from "./MainContent.module.scss";

// reactstrap components
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

// import MemeMatchLoader from "components/MemeMatch/MemeMatchLoader.jsx";
// import Leaderboard from "components/MainContent/Leaderboard4.jsx";
import RiskMatrix from "../MainContent/RiskMatrix.jsx";
// import Web3Connect from "../Buttons/Web3Connect.jsx";
import FutureMatchesTab from "../UpcomingMatches/FutureMatchesTab.jsx";
// import Searchbar from "./Searchbar2.jsx";
import ExplorerTab from "./ExplorerTab.jsx";
import LatestMatchTab from './LatestMatchTab.jsx'
import SettingsTab from './SettingsTab.jsx';
import BetaTab from './BetaTab.jsx';
import CommunityTab from "../CommunityTab/CommunityTab.jsx";

// Tab Titles + Defaults (by index)

var tabNames = {
  1: "LATEST_MATCH",
  2: "FUTURE_MATCHES",
  3: "LEADERBOARD", // or: COMMUNITY
  4: "EXPLORER",
  5: "BETA"
}


class MainAreaTabs extends Component {
  state = {
    tabChangesSinceRefresh: 0,
    currentTabIndex: null,
    currentTabTitle: "",
    // propsReceived: false,
  };

  componentDidMount() { 
    this.setState({
      currentTabTitle: tabNames[this.props.focusedTab],
      currentTabIndex: this.props.focusedTab
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true // TODO: this needs to be here but doesn't seem great
  }

  componentDidUpdate() {
    // console.log("MainAreaTabs.jsx - UPDATED");

    // this block detects tab-changes from outside component 
    // (ex: click BETA footer-link --> Navigate to Beta Tab)
    if (this.props.focusedTab != this.state.currentTabIndex) {
      // console.log("tab change from source other than direct navigation")
      this.changeTabs(tabNames[this.props.focusedTab], this.props.focusedTab)
    }
  }

  changeTabs = (nextTabTitle, nextTabIndex) => {
    const prevTabTitle = this.state.currentTabTitle;
    const prevTabIndex= this.state.currentTabIndex;
    const clickedAfterXTabChanges = this.state.tabChangesSinceRefresh;

    // TODO: mix of global and local state
    // this.props.changeFocusedTab(nextTabIndex);
    this.props.changeFocusedTab(nextTabIndex);

    this.setState({
      tabChangesSinceRefresh: this.state.tabChangesSinceRefresh + 1,
      currentTabTitle: nextTabTitle,
      currentTabIndex: nextTabIndex
    });

    // TODO: make this a different event than the one that first when you click tab
    // TODO: mixpanel
    // window.mixpanel.track("CHANGE_TAB", { 
    //   "CLICKED_TAB_TITLE": nextTabTitle,
    //   "CLICKED_TAB_INDEX": nextTabIndex,
    //   "PREV_TAB_TITLE": prevTabTitle,
    //   "PREV_TAB_INDEX": prevTabIndex,
    //   "CLICKED_ORDER": clickedAfterXTabChanges
    // }); 

    // Timer which ends when next next CHANGE_TAB occurs TODO: mixpanel
    // window.mixpanel.time_event('CHANGE_TAB');
  };

  render() {
    return (
      <div id={styles.mainAreaTabs}>
          {/* <Col className="ml-auto mr-auto"> */}
            <Card id={styles.mainTabsCard}>
              <CardHeader id={styles.mainTabsCardHeader}>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.focusedTab === 1
                      })}
                      onClick={e => this.changeTabs(tabNames[1], 1)}
                      // href="#mw"
                    >
                      <i
                        className="tim-icons icon-triangle-right-17"
                        id={styles.navTabIcon}
                      />
                      {/* <div id={styles.mainContentTabTitle}> Latest Match </div> */}
                      <div id="mainContentTabTitle"> Latest Vote </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.focusedTab === 2
                      })}
                      onClick={e => this.changeTabs(tabNames[2], 2)}
                      // href="#mw"
                    >
                      <i
                        className="tim-icons icon-double-right"
                        id={styles.navTabIcon}
                      />
                      {/* <div id={styles.mainContentTabTitle}> Future Matches </div> */}
                      <div id="mainContentTabTitle"> Future Votes </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.focusedTab === 3
                      })}
                      onClick={e => this.changeTabs(tabNames[3], 3)}
                      // href="#mw"
                    >
                      <i className="tim-icons icon-world" id={styles.navTabIcon} />
                      {/* <div id={styles.mainContentTabTitle}> Leaderboard </div> */}
                      <div id="mainContentTabTitle"> Community </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.focusedTab === 4
                      })}
                      onClick={e => this.changeTabs(tabNames[4], 4)}
                      // href="#mw"
                    >
                      <i
                        className="tim-icons icon-zoom-split"
                        id={styles.navTabIcon}
                      />
                     {/* <div id={styles.mainContentTabTitle}> Explorer </div> */}
                     <div id="mainContentTabTitle"> Tools </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.focusedTab === 5
                      })}
                      onClick={e => this.changeTabs(tabNames[5], 5)}
                      // href="#mw"
                    >
                      <i
                        className="tim-icons icon-settings-gear-63"
                        id={styles.navTabIcon}
                      />
                      {/* <div id={styles.mainContentTabTitle}> Settings </div> */}
                      <div id="mainContentTabTitle"> Beta </div>
                      
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody id={styles.mainAreaCardBody}>
                <TabContent
                  className="tab-space"
                  activeTab={"link" + this.state.currentTabIndex}
                >
                  <TabPane tabId="link1"> 
                    <LatestMatchTab   
                      // updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
                      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
                      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
                      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
                      // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
                      updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
                      updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
                      // ************************* 
                      profile={this.props.profile} // TODO: can get rid of this
                      // lobbyInfo={this.props.lobbyInfo}
                      // sessionState={this.props.sessionState}
                      // ************************* 
                      account={this.props.account} 
                      provider={this.props.provider}
                      joined={this.props.joined}
                      XPBalance={this.props.XPBalance} 
                      ETHbalance={this.props.ETHBalance} 
                      availableETH={this.props.availableETH}
                      lobby={this.props.lobby}
                      paid={this.props.paid} 
                      match={this.props.match}
                      currMatch={this.props.currMatch}
                      matches={this.props.matches}
                      proposals={this.props.proposals}
                      focusedTab={this.props.focusedTab}
                      focusedMatchID={this.props.focusedMatchID}
                      focusedEntry={this.props.focusedEntry}
                      loginComplete={this.props.loginComplete}
                      loginInProgress={this.props.loginInProgress}
                      //
                      demoMode={this.props.demoMode}
                    /> 
                  </TabPane>
                  <TabPane tabId="link2"> 
                    <FutureMatchesTab
                      updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
                      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
                      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
                      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
                      // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
                      // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
                      // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
                      // // ************************* 
                      // profile={this.props.profile}
                      // lobbyInfo={this.props.lobbyInfo}
                      // sessionState={this.props.sessionState}
                      // ************************* 
                      account={this.props.account} 
                      provider={this.props.provider}
                      joined={this.props.joined}
                      XPBalance={this.props.XPBalance} 
                      ETHbalance={this.props.ETHBalance} 
                      availableETH={this.props.availableETH}
                      lobby={this.props.lobby}
                      paid={this.props.paid} 
                      match={this.props.match}
                      currMatch={this.props.currMatch}
                      matches={this.props.matches}
                      proposals={this.props.proposals}
                      focusedTab={this.props.focusedTab}
                      focusedMatchID={this.props.focusedMatchID}
                      // focusedEntry={this.props.focusedEntry}
                      loginComplete={this.props.loginComplete}
                      loginInProgress={this.props.loginInProgress}
                      //
                      demoMode={this.props.demoMode}
                       /> 
                  </TabPane>
                  <TabPane tabId="link3">  
                      <CommunityTab 
                         demoMode={this.props.demoMode}
                         provider={this.props.provider}
                         network={this.props.network}
                      />
                  </TabPane>
                  <TabPane tabId="link4"> 
                  <ExplorerTab
                      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
                      //
                      account={this.props.account} 
                      provider={this.props.provider}
                      joined={this.props.joined}
                      network={this.props.network}
                      XPBalance={this.props.XPBalance} 
                      ETHbalance={this.props.ETHBalance} 
                      availableETH={this.props.availableETH}
                      lobby={this.props.lobby}
                      paid={this.props.paid} 
                      // match={this.props.match}
                      // currMatch={this.props.currMatch}
                      // matches={this.props.matches}
                      // proposals={this.props.proposals}
                      // focusedTab={this.props.focusedTab}
                      // focusedMatchID={this.props.focusedMatchID}
                      // focusedEntry={this.props.focusedEntry}
                      loginComplete={this.props.loginComplete}
                      loginInProgress={this.props.loginInProgress}
                      //
                      demoMode={this.props.demoMode}

                                          /> 
                    {/* <blockquote class="trello-board-compact" id="trelloEmbed">
                      <a href="https://trello.com/b/EHAGMGQR/wwwmemewars-public">Trello Board</a>
                    </blockquote> */}
                  </TabPane>
                  {/* <TabPane tabId="link5"> <SettingsTab/> </TabPane> */}
                  <TabPane tabId="link5"> 
                    <BetaTab 
                    changeTabFunction={(newTab) => this.changeTabs(tabNames[newTab], newTab)}
                    //
                    toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
                    demoMode={this.props.demoMode}
                    /> 
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          {/* </Col> */}
      </div>
    );
  }
}

export default MainAreaTabs;